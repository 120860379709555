import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"

class PilgrimagePage extends React.Component {

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale

    const title = "Pilgrimage"
    const post = data.wordpressPage
    const hasData = post.acf && post.acf.page_pilgrimage__flexcontent_page;

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate>
          <MainContainer className="px-5 sm:px-0 pt-3 pb-20">

            {hasData &&
              post.acf.page_pilgrimage__flexcontent_page.map((layout, i) => {
                if (layout.__typename === `WordPressAcf_layout_content`) {
                  return (
                    this.layoutContent(layout, i)
                  )
                }
                if (layout.__typename === `WordPressAcf_layout_card`) {
                  return (
                    this.layoutCard(layout, i)
                  )
                }
                return null
              })}

          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }

  layoutContent(layout, key) {
    return (
      <section key={`pilgrimage-layout-content-${key}`} className="pilgrimage-layout-content my-12">
        <div className="w-full sm:mx-auto sm:w-5/6" dangerouslySetInnerHTML={{ __html: layout.content }}></div>
      </section>
    )
  }

  layoutCard(layout, key) {

    const order = (layout.order === 'lr')
      ? ['order-1', 'order-2']
      : ['order-2', 'order-1'];

    let bgColor;
    let txtColor;
    switch (layout.theme) {
      case 'theme_a':
        bgColor = "bg-blue-300";
        txtColor = "text-purple-900";
        break;
      case 'theme_b':
        bgColor = "bg-pink-900";
        txtColor = "text-white";
        break;
      case 'theme_c':
        bgColor = "bg-yellow-900";
        txtColor = "text-purple-900";
        break;
      default:
        bgColor = "bg-blue-900";
        txtColor = "text-purple-900";
    }

    return (
      <section key={`pilgrimage-layout-card-${key}`} className="pilgrimage-layout-card my-12">
        <div className="w-full my-5">
          <div className={bgColor + " " + txtColor + " h-full overflow-hidden md:flex"}>
            <Img className={"md:w-1/2 " + order[0]} fluid={layout.image_url.localFile.childImageSharp.fluid} alt="" />
            <div className={"md:w-1/2 p-3 sm:p-10 " + order[1]} dangerouslySetInnerHTML={{ __html: layout.content }}>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PilgrimagePage

export const query = graphql`
  query($pId: Int!, $pType: String!) {
      wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
      acf {
        page_pilgrimage__flexcontent_page {
          ... on WordPressAcf_layout_content {
            __typename
            content
          }
          ... on WordPressAcf_layout_card {
            __typename
            theme
            order
            image_url{
              localFile {
                childImageSharp {
                  fluid(maxWidth: 450) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }              
            }
            content
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "jesedu2020-plain-dark.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
